/**
 * @generated SignedSource<<9a2533efdc0554e1003038c3ca551047>>
 * @relayHash dbd0b3d145609d7f85786f73738d10a3
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/492.0.0-2024-12-12T19:54:28.594Z/addCartItemMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PortfolioItemsAction = "ADD" | "EDIT" | "REMOVE" | "REORDER" | "%future added value";
export type PortfolioType = "CART" | "HEART" | "%future added value";
export type ModifyPortfolioItemsInput = {
  action: PortfolioItemsAction;
  clientMutationId?: string | null;
  endPortfolioItemId?: string | null;
  itemsIds?: ReadonlyArray<string | null> | null;
  note?: string | null;
  portfolioId?: string | null;
  portfolioItemsIds?: ReadonlyArray<string | null> | null;
  portfolioType?: PortfolioType | null;
  startPortfolioItemId?: string | null;
  targetPortfolioItemId?: string | null;
  userId: string;
};
export type addCartItemMutation$variables = {
  input: ModifyPortfolioItemsInput;
};
export type addCartItemMutation$data = {
  readonly modifyPortfolioItems: {
    readonly portfolio: {
      readonly items: {
        readonly totalResults: number | null;
      } | null;
    } | null;
  } | null;
};
export type addCartItemMutation = {
  response: addCartItemMutation$data;
  variables: addCartItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "PortfolioItemsConnection",
  "kind": "LinkedField",
  "name": "items",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalResults",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "addCartItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModifyPortfolioItemsPayload",
        "kind": "LinkedField",
        "name": "modifyPortfolioItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioV2",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "addCartItemMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModifyPortfolioItemsPayload",
        "kind": "LinkedField",
        "name": "modifyPortfolioItems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PortfolioV2",
            "kind": "LinkedField",
            "name": "portfolio",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "ferrum/492.0.0-2024-12-12T19:54:28.594Z/addCartItemMutation",
    "metadata": {},
    "name": "addCartItemMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "321aa184c3b379ab2a9f986679257e8d";

export default node;
